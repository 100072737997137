@media (max-width: 1000px) {
    .site-container {
        width: 100% !important; 
    }
    .display-1 {
        font-size: 32px !important;
    }
}

@media (max-width: 500px) {
    .gallery-container {
        top: unset;
    }
    .DigitalImage {
        width: 250px;
    }
    .DigitalImage img {
        height: 300px;
    }

    .TraditionalImage {
        width: 250px;
    }

    .TraditionalImage img {
        height: 300px;
    }
    
}

@media (max-width: 1600px) {
    .DigitalGalleryContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1400px) {
    .TraditionalGalleryContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 650px) {
    .container-mobile {
        display: none;
    }
}

@media (max-width: 650px) {
    .DigitalGalleryContainer {
        grid-template-columns: 1fr;
    }
    .TraditionalGalleryContainer {
        grid-template-columns: 1fr;
    }
    .display-2 {
        font-size: 2.5rem !important;
    }
    .illustration-spacer {
        height: unset;
    }
    .about-container {
        font-size: 14px;
    }
    .ConceptualTitle {
        font-size: 32px;
    }
    .ConceptualSubtitle {
        font-size: 17px;
    }
    .ConceptualGalleryContainer img {
        max-width: 275px;
    }
    .headshot img  {
        height: 250px;
    }
    .about-container {
        margin-left: 15px;
        margin-right: 15px;
    }
    .name-area {
        font-size: 12px;
    }
    .email-area {
        font-size: 12px;
    }
    .subject-area {
        font-size: 12px;
    }
    .message-area {
        font-size: 12px;
        height: 200px;
    }
    .submit-btn {
        height: 40px;
        width: 75px;
        font-size: 18px;
    }

    .TarotContainer {
        grid-template-columns: 
            1fr 1fr;
    
        grid-row-gap: 5px;
    }
    .ImageContainerOther {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 5px;
    } 
    .ImageContainerOther img {
        height: 150px;
    }

    .container-regular {
        display: none;
    }
    .subject-area{
        width: 100%;
    }
    .row {
        margin-top: 15px !important;
        
    }
    .container-mobile {
        margin-left: 15px;
        margin-right: 15px;
    }

    .col {
        max-width: 90.5% !important;
    }
}

.ConceptualTitle {
    font-weight: bold;
    font-size: 75px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.ConceptualSubtitle {
    font-size: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.ConceptualContainer {
    display: grid;
    justify-content: center;
    flex-direction: column;
}

.conceptual-spacer {
    height: 45px;
}
.OtherMediaImages img {
    max-height: 300px;
}

.TarotSlideshow {
    display: flex;
    justify-content: center;
}

.TarotSlideshow img{
    max-height: 200px;
}

.ImageContainerOther {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.OtherMediaImages {
    display: flex;
    justify-content: center;
}

.TarotContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 25px;
}

.tarot-container {
    margin-top: 45px;
}

.OtherTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

}

.tarot-header {
    display: flex;
    justify-content: center;
}

.pageheading {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    font-weight: bold;
}

.OtherImageContainer img {
    max-width: 350px;
}

.OtherContainer {
    margin-top: 50px;
}

.image img{
    max-height: 300px;
}
.gallery-container {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
    top: 300px;
}

.featured-work {
    color: white;
}

.CarouselContainer {
    display: flex;
    justify-content: center;
}

.homepage-container {
    background-image: url("../../static/assets/images/p294_orig.png");
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 86vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

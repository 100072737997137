.header-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.header {
    height: 125px;
}

.headerName {
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerName a:hover {
    text-decoration: none;
    color: #BD31A7;
    transition: ease-in-out 0.3s;
}

.headerName a {
    color: black;
    text-decoration: none;
}

.nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.portfoliomanager-link {
    font-size: 24px;
}

.portfoliomanager-link a {
    color: black;
}
.about-container {
    font-size: 32px;
}

.about-body {
    display: grid;
    justify-content: center;
}

.about-header-container {
    display: flex;
    justify-content: center;
}

.edit-about {
    font-size: 14px;
}

.add-accolade {
    font-size: 14px;
}

.headshot-container {
    display: flex;
    justify-content: center;
}

.headshotbutton {
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.seperator {
    height: 1px;
    background-color: black;
    margin-bottom: 8px;
}
@import 'navigation.scss';
@import 'manager.scss';
@import "~bootstrap/scss/bootstrap";
@import "homepage.scss";
@import "illustration.scss";
@import "conceptual.scss";
@import "about.scss";
@import "other.scss";
@import "contact.scss";
@import "gallery.scss";
@import "mediaqueries.scss";

@font-face {
    font-family: "Futura";
    src: local("Futura"),
    url("../../static/assets/fonts/futura\ medium\ bt.woff") format("woff");
}

html {
    font-size: 62.5%; // 1rem is 10px
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Futura', serif;
}

body {
    background-color: #f3f3f3;
    overflow-x: hidden;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Futura', serif;
}

.app {
    margin-top: 2rem;
    display: grid;
    justify-items: center;
}

.page-container {
    background-color: white;
}

.site-container {
    width: 70%;
}

.instagram a {
    color: black;
    font-size: 24px;
}